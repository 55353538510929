import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import { HeadingType, TextType, ButtonType } from "@utils/types";
import Text from "@ui/text";
import Heading from "@ui/heading";
// import QuoteForm from "@components/quote-form";
import {
  ConatactWrapper,
  // ContactInfoBox,
  // StyledHeading,
  // StyledText,
  // StyledInfoTitle,
  // StyledBG,
  ConatactWrapInner,
} from "./style";
const QuoteForm = React.lazy(() => import('@components/quote-form'));

const QuoteArea = ({ layout, ...props }) => {
  return (
    <ConatactWrapper layout={layout} {...props}>
      <Container>
        <Row alignItems="center">
          <Col lg={12}>
            <ConatactWrapInner>
              <Heading>Request a Quote</Heading>
              <Text>
                Please fill in a few details, upload files and/or brief, and we
                will contact you within a few hours.
              </Text>
              {typeof window !== 'undefined' && (
              <React.Suspense fallback={<div></div>}>
              <QuoteForm />
              </React.Suspense>
              )}
            </ConatactWrapInner>
          </Col>
        </Row>
      </Container>
    </ConatactWrapper>
  );
};

QuoteArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    layout: PropTypes.oneOf([1, 2, 3]),
  }),
};
QuoteArea.defaultProps = {
  layout: 1,
};

export default QuoteArea;
