import styled, { css, device, themeGet } from "@styled";
import orangewaveBg from "@data/images/bg/orangewaveBg.webp";
import quoteBG from "@data/images/bg/quote-bg.png";
import formQuotesBg from "@data/images/bg/formquotes-bg.png";

export const ConatactWrapper = styled.section`
  position: relative;
  isolation: isolate;
  padding: 80px 0 120px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(${formQuotesBg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  ${device.medium} {
    padding: 100px 0 120px;
  }
  ${device.large} {
    padding: 120px 0;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondaryDark")},
        ${themeGet("colors.secondary")}
      );
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.primaryDark")},
        ${themeGet("colors.primary")}
      );
    `}
  ${(props) =>
    props.layout === 3 &&
    css`
      padding: 166px 0;
      background: url(${orangewaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
    `}
`;

export const StyledBG = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
`;

export const ContactInfoBox = styled.div`
  text-align: center;
  margin-block-start: 45px;
  .icon {
    color: ${themeGet("colors.primary")};
    font-size: 40px;
  }
  ${device.large} {
    margin-block-start: 0;
  }
`;

export const StyledHeading = styled.h3`
  font-weight: 600;
  position: relative;
  line-height: 1.4;
  padding-inline-start: 34px;
  span {
    color: ${themeGet("colors.primary")};
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    width: 4px;
    height: 94%;
    transform: translateY(-50%);
    background-color: ${themeGet("colors.secondary")};
  }
`;

export const StyledText = styled.p`
  margin-block-start: 15px;
  font-size: 18px;
  margin-inline-start: 34px;
`;

export const StyledInfoTitle = styled.h6`
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-block-end: 10px;
  margin-block-start: 10px;
`;
export const ConatactWrapInner = styled.div`
  max-width: 830px;
  margin: 150px auto 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 15%;
    top: -100px;
    bottom: 0;
    background: url(${quoteBG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: -1;
    width: 226px;
    height: 238px;
  }
  h2 {
    font-size: 50px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 80px;
  }
  p {
    color: #dbb5f6;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: normal;
    max-width: 530px;
    margin: 0 auto 40px;
    ${device.small} {
      font-size: 22px;
    }
  }
`;
